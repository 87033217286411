import { ChargeOnBillToCompanyPolicy } from "constants/charge/ChargeOnBillToCompanyPolicy";
import { ChargeOnCompanyMappingEntity, EMPTY_CHARGE_ON_COMPANY_MAPPING_ENTITY } from "domain/entity/ChargeOnCompanyMapping/ChargeOnCompanyMappingEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ConsortiumRepository } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepo";
import { ChargeOnCompanyMappingRepository } from "domain/repository/ChargeOnCompanyMapping/ChargeOnCompanyMappingRepo";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { OpsLineRepository } from "domain/repository/OpsLine/OpsLineRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { SpecialHandlingIndMappingRepository } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepo";
import { VesselTypeRepository } from "domain/repository/VesselType/VesselTypeRepo";
import _ from "lodash";
import { ChargeOnCompanyMappingModel } from "presentation/model/ChargeOnCompanyMapping/ChargeOnCompanyMappingModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface ChargeOnCompanyMappingVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ChargeOnCompanyMappingModel>> | ((value: SetStateAction<ChargeOnCompanyMappingModel>) => void),
    ],
    chargeOnCompanyMappingRepo: ChargeOnCompanyMappingRepository,
    chargeTypeRepo: ChargeTypeRepository,
    specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepository,
    consortiumRepo: ConsortiumRepository,
    opsLineRepo: OpsLineRepository,
    masterDataRepo: MasterDataRepository,
    companyRepo: CompanyRepository,
    parameterDetailRepo: ParameterDetailRepository,
    vesselTypeRepo: VesselTypeRepository,
}

export const ChargeOnCompanyMappingVM = ({ dispatch, chargeOnCompanyMappingRepo, chargeTypeRepo, specialhandlingIndMappingRepo,
    consortiumRepo, opsLineRepo, masterDataRepo, companyRepo, parameterDetailRepo, vesselTypeRepo }: ChargeOnCompanyMappingVMProps) => {
    const [chargeOnCompanyMappingDispatch] = dispatch;

    const loadDropdownOption = async () => {
        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypeList = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let newChargeTypes = newChargeTypeList?.filter(entity => entity.chargeCategory === 'GEN' || entity.chargeCategory === 'SYS');
                let chargeTypeDropdownOption: DropdownProps[] = [];
                let subChargeTypeDropdownOption: { [key: string]: DropdownProps[] } = {};
                newChargeTypes?.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOption.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOption.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOption,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    }
                }))
            }
        )

        await specialhandlingIndMappingRepo.getAllSpecialHandlingIndMappings().then(
            specialHandlingIndList => {
                const specialHandlingindDropdownOption = specialHandlingIndList?.map((specialHandlingInd) => ({
                    dropdownLabel: specialHandlingInd.specialHandlingInd,
                    tagLabel: specialHandlingInd.specialHandlingInd,
                    value: specialHandlingInd.specialHandlingInd,
                })) ?? []

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        specHandingIndDropdownOptions: specialHandlingindDropdownOption,
                    }
                }))
            }
        )
        await opsLineRepo.getAllOpsLines().then(
            opsLines => {
                const opsLinesDropdownOptions = opsLines?.map((opsLineEntity) => ({
                    dropdownLabel: opsLineEntity.opsLine,
                    tagLabel: opsLineEntity.opsLine,
                    value: opsLineEntity.opsLine,
                })) ?? []

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        opsLineDropdownOptions: opsLinesDropdownOptions
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.TERMINAL).then(
            operatingTmls => {
                const operatingTmlDropdownOptions = operatingTmls?.map((operatingTml) => ({
                    dropdownLabel: operatingTml.code,
                    tagLabel: operatingTml.code,
                    value: operatingTml.code,
                })) ?? []

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: operatingTmlDropdownOptions,
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.MARSHAL).then(
            marshalEntities => {
                const marshalCodeDropdownOptions = marshalEntities?.map((marshal) => ({
                    dropdownLabel: marshal.code,
                    tagLabel: marshal.code,
                    value: marshal.code,
                })) ?? []

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        marshalCodeDropdownOptions: marshalCodeDropdownOptions,
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.HANDLING_CODE).then(
            chargeCodes => {
                const chargeCodeDropdownOptions = chargeCodes?.map((chargeCode) => ({
                    dropdownLabel: chargeCode.code,
                    tagLabel: chargeCode.code,
                    value: chargeCode.code,
                })) ?? []

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeCodeDropdownOptions: chargeCodeDropdownOptions,
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.MODALITY).then(
            modalityEntities => {
                const modalityCodeDropdownOptions = modalityEntities?.map((modality) => ({
                    dropdownLabel: modality.code,
                    tagLabel: modality.code,
                    value: modality.code,
                })) ?? []

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        modalityDropdownOptions: modalityCodeDropdownOptions,
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.FORWARDER_CODE).then(
            forwarderCodes => {
                const forwarderCodesDropdownOptions = forwarderCodes?.map((forwarderCode) => ({
                    dropdownLabel: forwarderCode.code,
                    tagLabel: forwarderCode.code,
                    value: forwarderCode.code,
                })) ?? []

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        forwarderCodeDropdownOptions: forwarderCodesDropdownOptions,
                    }
                }))
            }
        )
        await consortiumRepo.getAllConsortiums().then(
            consortiums => {
                let consortiumCodeDropdownOptions = consortiums?.map((item) => ({
                    dropdownLabel: item.consortiumCode,
                    tagLabel: item.consortiumCode,
                    value: item.consortiumCode,
                })) ?? []
                consortiumCodeDropdownOptions = _.orderBy(consortiumCodeDropdownOptions, "dropdownLabel");

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        consortiumCodeDropdownOptions: consortiumCodeDropdownOptions,
                    }
                }))
            }
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.SERVICE).then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode) => ({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: serviceCodeDropdownOptions
                    }
                }))
            }
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.SOA).then(
            soas => {
                const soaCodeDropdownOptions = soas?.map((soa) => ({
                    dropdownLabel: soa.code,
                    tagLabel: soa.code,
                    value: soa.code,
                })) ?? []

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        soaDropdownOptions: soaCodeDropdownOptions
                    }
                }))
            }
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.DUMMY_CHG_ON_BILL_TO).then(
            async dummyCompanies => {
                let dummyCompanyCodeDropdownOptions = dummyCompanies?.map((dummyCompany) => ({
                    dropdownLabel: dummyCompany.code,
                    tagLabel: dummyCompany.code,
                    value: dummyCompany.code,
                })) ?? []
                await companyRepo.getAllCompanyForCombobox().then(
                    companies => {
                        let companyCodeDropdownOptions = companies?.map((company) => ({
                            dropdownLabel: company.companyCode,
                            tagLabel: company.companyCode,
                            value: company.companyCode,
                        })) ?? []
                        companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                        chargeOnCompanyMappingDispatch(prevState => ({
                            ...prevState,
                            dynamicOptions: {
                                ...prevState.dynamicOptions,
                                chargeOnCompanyDropdownOptions: [...dummyCompanyCodeDropdownOptions, ...companyCodeDropdownOptions],
                            }
                        }))
                    }
                )
            }
        );

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("MARSHALLING_TYPE").then(
            marshallingTypeList => {
                const marshallingTypeDropdownOption = marshallingTypeList?.map((marshallingType) => ({
                    dropdownLabel: marshallingType.parameterDtlCode,
                    tagLabel: marshallingType.parameterDtlCode,
                    value: marshallingType.parameterDtlCode,
                })) ?? []

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        marshallingTypeDropdownOptions: marshallingTypeDropdownOption
                    }
                }))
            }
        )
        await vesselTypeRepo.getVesselTypeForComboBox().then(
            vesselTypeList => {
                const vesselTypeDropdownOption = vesselTypeList?.map((vesselType) => ({
                    dropdownLabel: vesselType.vesselType,
                    tagLabel: vesselType.vesselType,
                    value: vesselType.vesselType,
                })) ?? []

                chargeOnCompanyMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        vesselTypeDropdownOptions: vesselTypeDropdownOption
                    }
                }))
            }
        )
    }

    const searchAllChargeOnCompanyMappingList = async () => {
        chargeOnCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentRow: { ...EMPTY_CHARGE_ON_COMPANY_MAPPING_ENTITY },
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                updatedRows: [],
                chargeOnCompanyMappingList: [],
            }
        })
        await chargeOnCompanyMappingRepo.searchAllChargeOnCompanyMappingList().then(data => {
            if (data) {
                const newData = data?.map(entity => ({
                    ...entity,
                    chargeOnCompanyCode: ChargeOnBillToCompanyPolicy().getChargeOnBillToCompanyKeyByValue(entity.chargeOnCompanyCode as string),
                    alterableChargeOnCompany: ChargeOnBillToCompanyPolicy().getChargeOnBillToCompanyKeyByValue(entity.alterableChargeOnCompany as string),
                }));
                chargeOnCompanyMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        chargeOnCompanyMappingList: newData,
                    }
                })
            } else {
                chargeOnCompanyMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        chargeOnCompanyMappingList: [],
                    }
                })
            }
        })
    }

    // const onRowDrag = (rows: ChargeOnCompanyMappingEntity[]) => {
    //     chargeOnCompanyMappingDispatch(prevState =>{ 
    //         const prevRows = prevState.chargeOnCompanyMappingList;
    //         const toChangedRows = rows?.map((e, index) => ({...e,tempPriority: prevRows[index].priority}))?.filter(e=>e.tempPriority!==e.priority);
    //         return ({
    //             ...prevState,
    //             chargeOnCompanyMappingList: rows,
    //             updatedRows: toChangedRows,
    //     })})
    // }
    const onApply = async (updatedRows: ChargeOnCompanyMappingEntity[]) => {


        return await chargeOnCompanyMappingRepo.onApply(updatedRows);
    }

    const updateSelectedRows = async (rows: ChargeOnCompanyMappingEntity[]) => {

        chargeOnCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                // chargeOnCompanyMappingList: [...allRows],
                selectedRows: rows,
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const onRowDoubleClick = async (entity: ChargeOnCompanyMappingEntity) => {
        chargeOnCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: entity,
                currentRow: entity,
                isShowEditPanel: true,
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        if (fieldKey === 'chargeType') {
            chargeOnCompanyMappingDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        subChargeType: val === prevState.currentEditRow.chargeType ? prevState.currentEditRow.subChargeType : ''
                    },
                }
            })
        }
        chargeOnCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onCloseEidtPanel = async () => {
        chargeOnCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: { ...EMPTY_CHARGE_ON_COMPANY_MAPPING_ENTITY },
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
            }
        })
    }

    const onSave = async (currentRow: ChargeOnCompanyMappingEntity) => {
        currentRow = {
            ...currentRow,
            chargeOnCompanyCode: ChargeOnBillToCompanyPolicy().getChargeOnBillToCompanyValueByKey(currentRow.chargeOnCompanyCode ?? ""),
            alterableChargeOnCompany: ChargeOnBillToCompanyPolicy().getChargeOnBillToCompanyValueByKey(currentRow.alterableChargeOnCompany ?? ""),
        }
        return chargeOnCompanyMappingRepo.onSave(currentRow);
    }

    const onAddClick = async () => {
        chargeOnCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: { ...EMPTY_CHARGE_ON_COMPANY_MAPPING_ENTITY },
                isShowEditPanel: true,
                isAdd: true,
            }
        })
    }
    const onResetClick = async (isAdd: boolean) => {
        chargeOnCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: isAdd ? { ...EMPTY_CHARGE_ON_COMPANY_MAPPING_ENTITY } : prevState.currentRow,
            }
        })
    }
    const onEditClick = async () => {
        chargeOnCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                isEdit: true,
            }
        })
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        chargeOnCompanyMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: checked ? "Y" : "N",
                },
            }
        })
    }

    return {
        onCheckboxChange: onCheckboxChange,
        onEditClick: onEditClick,
        onResetClick: onResetClick,
        onSave: onSave,
        onAddClick: onAddClick,
        onCloseEidtPanel: onCloseEidtPanel,
        onHeaderFieldChange: onHeaderFieldChange,
        onRowDoubleClick: onRowDoubleClick,
        updateSelectedRows: updateSelectedRows,
        onApply: onApply,
        // onRowDrag: onRowDrag,
        loadDropdownOption: loadDropdownOption,
        searchAllChargeOnCompanyMappingList: searchAllChargeOnCompanyMappingList,
    }
}